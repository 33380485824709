import {
  testMyRentalsShallowMount,
  myRentalsShallowMount,
} from "@/tests/unit/setup";
import PropertyInfo from "@/components/PropertyInfo.vue";
import OwnerCardsMappingService from "@/services/OwnerCardsMappingService";

const defaultProps = {
  info: {
    propertyId: 1234,
    propertyUuid: "123d-asda",
    location: {},
    rentalMandateSignatureDate: null,
    numberOfTimesRented: 12,
    fullAddress: "Av. Test, 01",
    floor: "01",
    door: "B",
    photos: ["asdasda-1231231"],
    isVisible: false,
    propertyStatus: 2,
    lastPropertyStatusChangedAt: "2023-01-01",
    rentalMandatePack: 2,
    amountOfIncome: { amount: 1234, currency: "EUR" },
    amountOfCharges: { amount: 1234, currency: "EUR" },
    images: [
      {
        uuid: "111222-azxd1-asd",
        downloadUrl: "www.test.es",
        viewUrl: "www.test.es",
        url: "www.test.es",
        expiresAt: "string",
      },
    ],
    fullAdress: "C. Test, 01",
    rentedDate: "1988-01-01",
    monthlyPrice: { amount: 1234, currency: "EUR" },
    statusCategory: "PendingAd",
  },
  leasings: {
    items: [
      {
        leasingId: 12,
        status: 3,
        lastStatusChangedAt: "2023-01-01",
        leasingContractValidityDate: null,
        leasingContractSignatureDate: null,
        leasingTerminationDate: null,
        monthlyPrice: null,
      },
    ],
  },
};

jest.mock("@/services/MyRentalsConfig");

const component = (props = {}) => {
  return myRentalsShallowMount(PropertyInfo, {
    props: { ...defaultProps, ...props },
  });
};

beforeEach(() => {
  jest.restoreAllMocks();
});

describe("PropertyInfo", () => {
  testMyRentalsShallowMount(PropertyInfo, { props: defaultProps });

  describe("Computed", () => {
    describe("activeProcessCardTypes", () => {
      it("should call OwnerCardsMappingService.getActiveProcessCardTypes with correct props", () => {
        // given
        const mockedPropertyStatusToTest = 1;
        const isVisibleToTest = true;
        const rentalPublishingDate = "2023-01-01";
        const mockedLeasings = {
          items: [
            {
              leasingId: 45,
              status: mockedPropertyStatusToTest,
              lastStatusChangedAt: "2023-01-01",
              leasingContractValidityDate: null,
              leasingContractSignatureDate: null,
              leasingTerminationDate: null,
              monthlyPrice: null,
            },
          ],
        };
        const mockedProps = {
          info: {
            propertyStatus: mockedPropertyStatusToTest,
            isVisible: isVisibleToTest,
            rentalPublishingDate,
          },
          leasings: mockedLeasings,
        };
        spyOn(
          OwnerCardsMappingService,
          "getActiveProcessCardTypes"
        ).and.returnValue("getActiveProcessCardTypes");
        component(mockedProps);
        // when
        // then
        expect(
          OwnerCardsMappingService.getActiveProcessCardTypes
        ).toHaveBeenCalledWith(
          mockedPropertyStatusToTest,
          mockedLeasings.items,
          isVisibleToTest,
          rentalPublishingDate
        );
      });
    });
    describe("showProcessCardAsButton", () => {
      it("should return true if propertyStatus is published and activeProcess includes published", () => {
        // given
        const mockedPropertyStatusToTest = 3;
        const isVisibleToTest = true;
        const mockedLeasings = {
          items: [
            {
              leasingId: 45,
              status: mockedPropertyStatusToTest,
              lastStatusChangedAt: "2023-01-01",
              leasingContractValidityDate: null,
              leasingContractSignatureDate: null,
              leasingTerminationDate: null,
              monthlyPrice: null,
            },
          ],
        };
        const mockedProps = {
          info: {
            propertyStatus: mockedPropertyStatusToTest,
            isVisible: isVisibleToTest,
          },
          leasings: mockedLeasings,
        };
        spyOn(
          OwnerCardsMappingService,
          "getActiveProcessCardTypes"
        ).and.returnValue(["Published"]);
        const wrapper = component(mockedProps);
        // when
        // then
        expect(wrapper.vm.showProcessCardAsButton).toBe(true);
      });
      it("should return true if propertyStatus is NOT published but activeProcess includes published", () => {
        // given
        const mockedPropertyStatusToTest = 15;
        const isVisibleToTest = true;
        const mockedLeasings = {
          items: [
            {
              leasingId: 45,
              status: mockedPropertyStatusToTest,
              lastStatusChangedAt: "2023-01-01",
              leasingContractValidityDate: null,
              leasingContractSignatureDate: null,
              leasingTerminationDate: null,
              monthlyPrice: null,
            },
          ],
        };
        const mockedProps = {
          info: {
            propertyStatus: mockedPropertyStatusToTest,
            isVisible: isVisibleToTest,
          },
          leasings: mockedLeasings,
        };
        spyOn(
          OwnerCardsMappingService,
          "getActiveProcessCardTypes"
        ).and.returnValue(["Published"]);
        const wrapper = component(mockedProps);
        // when
        // then
        expect(wrapper.vm.showProcessCardAsButton).toBe(false);
      });
      it("should return false if propertyStatus is published and activeProcess NOT include published", () => {
        // given
        const mockedPropertyStatusToTest = 3;
        const isVisibleToTest = true;
        const mockedLeasings = {
          items: [
            {
              leasingId: 45,
              status: mockedPropertyStatusToTest,
              lastStatusChangedAt: "2023-01-01",
              leasingContractValidityDate: null,
              leasingContractSignatureDate: null,
              leasingTerminationDate: null,
              monthlyPrice: null,
            },
          ],
        };
        const mockedProps = {
          info: {
            propertyStatus: mockedPropertyStatusToTest,
            isVisible: isVisibleToTest,
          },
          leasings: mockedLeasings,
        };
        spyOn(
          OwnerCardsMappingService,
          "getActiveProcessCardTypes"
        ).and.returnValue(["CapturedNotClickable"]);
        const wrapper = component(mockedProps);
        // when
        // then
        expect(wrapper.vm.showProcessCardAsButton).toBe(false);
      });
    });
    describe("activeProcessesTitle", () => {
      it.each(["PendingRecover", "PendingRecoverFutureDate"])(
        "should return true when getActiveProcessCardTypes returns %s",
        (param) => {
          // given
          spyOn(
            OwnerCardsMappingService,
            "getActiveProcessCardTypes"
          ).and.returnValue([param]);
          const wrapper = component();
          // when
          // then
          expect(wrapper.vm.activeProcessesTitle).toBe(true);
        }
      );
    });
  });
});
